//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core';
import { mq, variables } from '../../../cssInJs';

export default {
  pageTiles: css({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '2rem -10px',
    overflow: 'hidden',
  }),
  pageTile__tile: css(
    mq({
      flex: '0 0 100%',
      maxWidth: '100%',
      padding: '10px',
      '&:nth-of-type(even)': {
        '.pageTile__big__special__bg': mq({
          order: ['0', null, '2'],
        }),
        '.pageTile__big__special__content__details': mq({
          order: '1',
        }),
      },
    })
  ),

  tile__content: css({
    //     display: 'flex',
    //     flexDirection: 'row',
    //     justifyContent: 'space-between',
    //     width: '100%',
    //     height: '100%',
    //     cursor: 'pointer',
    //     position: 'relative',
    //     flexWrap: 'wrap',
    '&:hover': {
      textDecoration: 'none',
      '& .pageTile__big__special__content__details': {
        color: 'white',
        backgroundColor: variables.colorPurple,
        // backgroundImage: 'url(/assets/grey-bottle-img.png)',
        '& .pageTile__big__special__learn__more': {
          backgroundColor: 'white',
          borderColor: 'white',
          color: variables.colorPurple,
        },
        // '&.default' : css({
        //      backgroundColor: 'rgba(0,113,188,1)'
        // }),
        // '&.red' : css({
        //      backgroundColor: 'rgba(211,61,2,1)'
        // }),
        // '&.green' : css({
        //      backgroundColor: 'rgba(0, 146, 69,1)'
        // })
      },
      '& .pageTile__big__special__bg2': {
        //transform: scale(1.05)
        transform: 'scale(1.05)',
      },
    },
  }),

  tile__flip__wrapper: css({
    zIndex: '-1',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    position: 'relative',
    flexWrap: 'wrap',
  }),

  //     tile__bg : css(mq({
  //          height: '0',
  //          //paddingTop: '30%',
  //          paddingTop: ['50%','35%','30%'],
  //          backgroundPosition: '50%',
  //          backgroundRepeat: 'no-repeat',
  //          backgroundSize: 'cover',
  //          flex: '1 1 300px'
  //     })),

  tile__bg: css(
    mq({
      height: '0',
      paddingTop: ['50%', '35%', '30%'],
      //height: ['50%','35%','30%'],
      //flex: '1 1 300px',
      flex: ['1 0 100%', null, '1 0 39.7%'],
      maxWidth: ['100%', null, '39.7%'],
      overflow: 'hidden',
      position: 'relative',
      boxShadow: '0px 5px 5px rgba(0,0,0,0.5)',
    })
  ),

  tile__bg2: css(
    mq({
      height: '100%',
      backgroundPosition: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      //overflow:'hidden',
      transition: 'all .2s',
      position: 'absolute',
      top: '0px',
      width: '100%',
    })
  ),

  tile__details: css(
    mq({
      overflow: 'hidden',
      flexGrow: '1',
      textAlign: 'left',
      width: '100%',
      bottom: '0',
      backgroundColor: variables.colorGreen,
      // backgroundImage: 'url(/assets/red-bottle-img.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      boxShadow: '0px 5px 5px rgba(0,0,0,0.5)',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      marginTop: ['0.5rem', null, '0'],
      flex: ['1 0 100%', null, '1 0 59.7%'],
      maxWidth: ['100%', null, '59.7%'],
      transition: 'all .2s ease-in-out',
      color: '#ebebeb',
      position: 'relative',
      '& .pageTile__big__special__container': mq({
        position: ['static,', null, 'absolute'],
        top: '50%',
        left: '50%',
        transform: ['none', null, 'translate(-50%, -50%)'],
        width: ['100%', null, '90%'],
        paddingLeft: '2rem',
        paddingRight: '2rem',
        paddingTop: '0',
        paddingBottom: '0',
        '& .pageTile__big__special__title': {
          fontSize: ['1.5rem', null, '2rem'],
          lineHeight: '1',
          margin: '0',
          color: 'inherit',
          fontWeight: 600,
          borderBottom: '1px solid white',
          display: 'inline-block',
        },
        '& .pageTile__big__special__desc': {
          fontSize: '.9rem',
          color: 'inherit',
          marginTop: '1rem',
        },
        '& .pageTile__big__special__learn__more': {
          display: 'inline-block',
          border: '1px solid white',
          marginTop: '1rem',
          padding: '0.1rem 1.5rem',
          transition: 'all .2s ease-in-out',
          paddingBottom: '0.2rem',
        },
      }),
    })
  ),
};
