import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    {
      url: '/wine-movement-story',
      title: 'OUR STORY',
      icon: 'navicon-wine-movement.png',
      iconHover: 'navicon-wine-movement-hover.png',
    },
    {
      url: '/services',
      title: 'SERVICES',
      icon: 'navicon-wine-movement.png',
      iconHover: 'navicon-wine-movement-hover.png',
    },
    
    {
      url: '/wine-movement-news',
      title: 'NEWS',
      icon: 'navicon-wine-movement.png',
      iconHover: 'navicon-wine-movement-hover.png',
    },
    {
      url: '#contact',
      title: 'CONTACT',
      icon: 'navicon-wine-movement.png',
      iconHover: 'navicon-wine-movement-hover.png',
    },
    // {
    //   url: '/wine-club',
    //   title: 'CLUB',
    //   icon: 'navicon-wine-movement.png',
    //   iconHover: 'navicon-wine-movement-hover.png',
    // },
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  };

  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className="icon">
          <img
            className="normal"
            src={`/assets/navicons/${link.icon}`}
            alt={link.title}
          />
          <img
            className="selected"
            src={`/assets/navicons/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  return (
    <>
      <nav className="bottomMenu">
        <ul className="bottomMenu__links">{linkElts}</ul>
      </nav>
    </>
  );
}
