const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600],
};

const colors = {
  colorBlue: '#007bff',
  colorDarkRed: '#8a273b',
  colorGrey: '#838281',
  colorGreen: '#0ca852',
  colorPurple: '#850891',
};

const variables = {
  familyBase: '"acumin-pro-condensed",sans-serif',
  familyHeader: '"acumin-pro-condensed",serif',

  primaryColor: colors.colorBlue,
  fontColor: 'black',
  bgColor: '#ebebeb',

  ...colors,
  ...overloading,
};

export default variables;
